<template>
  <div class="view-offer">
    <loading-screen :is-loading="isAccepting"></loading-screen>
    <v-container fluid>
      <div class="welcome">
        <div class="left">
          <v-btn @click.prevent.native="backToAll" icon color="blue">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>

          <h1>{{ title }}</h1>
        </div>
        <div class="right">
          <v-btn size="large" depressed color="primary" :disabled="isAccepting" @click.prevent="confirmAcceptance">
            Accept Offer
          </v-btn>
        </div>
      </div>

      <!-- Do some loading thing here -->
      <div class="loading" v-if="isLoading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <!-- / Do some loading thing here -->

      <!-- Loaded -->
      <v-row v-if="!isLoading && offer">
        <v-col cols="12" md="6">
          <product-images-gallery :product="offer.offer.product"></product-images-gallery>
        </v-col>
        <v-col cols="12" md="6">
          <base-card>
            <v-card-title>Offer Details</v-card-title>
            <v-card-text>
              <!-- Offer Type -->
              <div class="offer-property">
                <b>Offer Type</b>
                <br />
                {{ isGroupOffer ? "Group Offer" : "Exclusive Offer" }}
              </div>
              <!-- / Offer Type -->
              <!-- Purchase Price -->
              <div v-if="offer.offer.product.purchase_price" class="offer-property">
                <b>Purchase Price</b>
                <br />
                {{
                    formatCostInPence(
                      offer.offer.product.purchase_price,
                      offer.offer.product.currency
                    )
                }}
              </div>
              <!-- / Purchase Price -->

              <!-- Expected Sale Price -->
              <div v-if="offer.offer.product.expected_sale_price" class="offer-property">
                <b>Expected Sale Price</b>
                <br />
                {{
                    formatCostInPence(
                      offer.offer.product.expected_sale_price,
                      offer.offer.product.currency
                    )
                }}
              </div>
              <!-- / Expected Sale Price -->

              <!-- Offer Expires At -->
              <div v-if="offer.offer.expires_at" :key="timeAgoRefreshKey" class="offer-property">
                <b>Offer Expires</b>
                <br />
                {{ expiresAtLabel }}
              </div>
              <!-- / Offer Expires At -->
            </v-card-text>
          </base-card>

          <base-card class="mt-5">
            <v-card-title>Product Details</v-card-title>
            <v-card-text>
              <!-- Colour -->
              <div class="offer-property">
                <b>Colour</b>
                <br />
                <span v-if="offer.offer.product.bag_colour">
                  {{ offer.offer.product.bag_colour.name }} <span v-if="offer.offer.product.bag_colour.group">({{
                      offer.offer.product.bag_colour.group
                  }})</span>
                </span>
                <span v-else>Not provided</span>
              </div>
              <!-- / Colour -->

              <!-- Condition -->
              <div class="offer-property">
                <b>Condition</b>
                <br />
                <span v-if="offer.offer.product.bag_condition">
                  {{ offer.offer.product.bag_condition.description }}
                </span>
                <span v-else>Not provided</span>
              </div>
              <!-- / Condition -->

              <!-- Style -->
              <div class="offer-property">
                <b>Style</b>
                <br />
                <span v-if="offer.offer.product.bag_style">
                  {{ offer.offer.product.bag_style.description }}
                </span>
                <span v-else>Not provided</span>
              </div>
              <!-- / Style -->

              <!-- Skin -->
              <div class="offer-property">
                <b>Skin</b>
                <br />
                <span v-if="offer.offer.product.bag_skin">
                  {{ offer.offer.product.bag_skin.name }}
                </span>
                <span v-else>Not provided</span>
              </div>
              <!-- / Skin -->

              <!-- Size -->
              <div class="offer-property">
                <b>Size</b>
                <br />
                <span v-if="offer.offer.product.bag_size">
                  {{ offer.offer.product.bag_size.description }}
                </span>
                <span v-else>Not provided</span>
              </div>
              <!-- / Size -->

              <!-- Hardware -->
              <div class="offer-property">
                <b>Hardware</b>
                <br />
                <span v-if="offer.offer.product.hardware">
                  {{ offer.offer.product.hardware.name }}
                </span>
                <span v-else>Not provided</span>
              </div>
              <!-- / Hardware -->
            </v-card-text>
          </base-card>

          <!-- <v-btn class="mt-6" depressed color="primary" :disabled="isAccepting" @click.prevent="confirmAcceptance">
            Accept Offer
          </v-btn> -->

          <v-dialog v-model="showConfirmModal" max-width="400">
            <v-card>
              <v-card-title class="text-h5 mb-4"> Please Confirm </v-card-title>

              <v-card-text>
                You will have until the end of the day to pay {{
                    formatCostInPence(
                      offer.offer.product.purchase_price,
                      offer.offer.product.currency
                    )
                }}
                or this will be offered to everyone again
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="red darken-1" text @click="showConfirmModal = false">
                  Cancel
                </v-btn>

                <v-btn color="primary darken-1" text @click="acceptOffer">
                  Confirm
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <!-- / Loaded -->
    </v-container>
  </div>
</template>

<script>
import TimeAgoRefresher from "../../../../mixins/TimeAgoRefresher";
import Products from "../../../../mixins/Products";
import Dates from "../../../../mixins/Dates";
import Forms from "../../../../mixins/Forms";
import Display from "../../../../mixins/Display";
import offers from "../../../../api/offers";
import ProductImagesGallery from "../../../../components/gallery/ProductImagesGallery.vue";
import LoadingScreen from "../../../../components/LoadingScreen.vue";
const _ = require("lodash");
import { mapActions } from "vuex";

export default {
  data() {
    return {
      isLoading: false,
      offer: null,

      showConfirmModal: false,
      isAccepting: false,
    };
  },

  components: { ProductImagesGallery, LoadingScreen },

  mixins: [TimeAgoRefresher, Products, Dates, Display, Forms],

  created() {
    this.loadOffer();
  },

  methods: {
    ...mapActions("accounts", {
      fetch: "fetch",
    }),

    backToAll() {
      this.$router.push("/offers");
    },

    loadOffer() {
      let vm = this;
      vm.isLoading = true;
      vm.offer = null;
      offers
        .get(this.offerId)
        .then((r) => {
          vm.isLoading = false;
          vm.offer = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
          vm.$toast.error("Offer not found");
          vm.$router.push("/offers");
        });
    },

    confirmAcceptance() {
      let vm = this;
      vm.showConfirmModal = true;
    },

    acceptOffer() {
      let vm = this;
      vm.showConfirmModal = false;
      vm.isAccepting = true;
      offers
        .acceptOffer(this.offerId)
        .then((r) => {
          vm.isAccepting = false;
          vm.$toast.success("Offer accepted successfully");
          vm.fetch();
          vm.$router.push("/");
        })
        .catch((e) => {
          let errors = vm.getErrorsAsArray(e);
          let error;
          if (errors.length) {
            error = _.first(errors);
          } else {
            error = "Something went wrong. Please try again.";
          }
          vm.$toast.error(error);
          vm.isAccepting = false;
        });
    },
  },

  computed: {
    offerId() {
      return this.$route.params.id;
    },

    title() {
      if (this.isLoading) {
        return "Loading...";
      }
      if (this.offer) {
        return this.offer.offer.product.name;
      }
      return "Unknown";
    },

    isGroupOffer() {
      if (!this.offer) {
        return null;
      }
      return this.offer.offer.is_group_offer;
    },

    expiresAtLabel() {
      if (!this.offer) {
        return null;
      }
      return "Expires " + this.displayTimeAgo(this.offer.offer.expires_at);
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  text-align: center;
  padding-top: 20px;
}

.view-offer {
  padding: 20px;

  .welcome {
    margin-bottom: 20px;
    display: flex;
    // justify-items: center;

    .left {
      display: flex;
      justify-items: center;
      flex-grow: 1;
    }

    .right {
      flex-shrink: 1;
    }

    h1 {
      margin-left: 10px;
    }
  }

  .offer-property {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}
</style>